$(document).ready(function () {
	$(".submenu").hide();
	$(".nav-item a").click(function(){
		$(this).next(".submenu").slideToggle(300);
		$(this).toggleClass("active");
		$(this).find($(".fa")).toggleClass('fa-angle-down fa-angle-up');
	});
	
	$(".projecItems").hide();
	$(".projecCategory").click(function(){
		$(this).next(".projecItems").toggle(300);
		$(this).toggleClass("active");
		$(this).find($(".fa")).toggleClass('fa-angle-down fa-angle-up');
	});
	
	
	$(".navbar-toggler").click(function(){		
		$(".content-container").toggleClass("collapseNav");
		$(".navbar").toggleClass("collapseNav");
	});
	
	
});



	
